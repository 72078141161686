import Head from "@/components/Head/index.vue";
import Tabs from "@/components/Tabs/index.vue";
import Pagination from "@/components/Pagination/index.vue";
import { defineComponent, onActivated, onMounted, reactive, watch, ref, nextTick } from "vue";
import List from "./components/List.vue";
export default defineComponent({
  name: "AnnualCheck",
  components: {
    Tabs: Tabs,
    Head: Head,
    Pagination: Pagination,
    List: List
  },
  setup: function setup() {
    var table1 = ref();
    var table2 = ref();
    var Data = reactive({
      titleArr: ["待评测", "评测历史"],
      topTabsIndex: 0,
      tabsIndex: 0
    });
    onMounted(function () {
      getList();
    }); // 切换 自评 / 考核人  0 / 1

    var handleTabsChange = function handleTabsChange(name) {
      Data.topTabsIndex = name;

      switch (name) {
        case 0:
          Data.titleArr = ["待评测", "评测历史"];
          break;

        case 1:
          Data.titleArr = ["待考核", "考核历史"];
          break;
      }
    }; // 获取Tab索引值


    var tabsChange = function tabsChange(val) {
      Data.tabsIndex = val;
    };

    function getList() {
      nextTick(function () {
        if (Data.tabsIndex == 0) {
          table1.value.getTabledata();
        } else {
          table2.value.getTabledata();
        }
      });
    } // 监听tabs索引值改变


    watch(function () {
      return Data.tabsIndex;
    }, function (val) {
      nextTick(function () {
        if (val == 0) {
          table1.value.initData();
        } else {
          table2.value.initData();
        }
      });
    }); // 身份改变

    watch(function () {
      return Data.topTabsIndex;
    }, function () {
      getList();
    }); //刷新列表

    onActivated(function () {
      if (sessionStorage.getItem("keepActive") == "true") {
        getList();
        sessionStorage.removeItem("keepActive");
      }
    });
    return {
      Data: Data,
      table1: table1,
      table2: table2,
      tabsChange: tabsChange,
      handleTabsChange: handleTabsChange
    };
  }
});